.screen-smooth-scroll{
  position: fixed;
  top: 64%;
  left: 30px;
  transform: translateY(-50%);
  display: none;
  flex-direction: column;
  width: 70px;
  z-index: 90;

  @include break-max($lap){
    min-width: 40px;
    left: 15px;
  }

  @include break-min(1660px){
    display: flex;
  }

  .divider{
    display: block;
    height: 4px;
  }

  .screen--smooth-nav{
    display: flex;
    padding: 0 5px 0 0;
    height: 40px;
    margin-bottom: 0px;
    border-radius: 25px;
    align-items: center;
    position: relative;
    outline: none !important;
    text-decoration: none !important;

    &:last-child{
      margin-bottom: 0;
    }

    .smooth-scroll-icon{
      position: relative;
      color: $primary-blue;
      background: #fff;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 0.7;
      transition: all 0.2s ease-in;
      z-index: 4;

      svg{
        height: 16px;
        path{
          fill: $dark-blue;
        }
      }
    }

    .smooth-scroll-text{
      display: inline-flex;
      align-items: center;
      height: 100%;
      border-radius: 25px;
      background: #fff;
      color: $dark-blue;
      font-weight: 400;
      position: absolute;
      left: 0;
      padding-right: 25px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }

    &:hover{
      .smooth-scroll-icon{
        opacity: 0.9;
        box-shadow: -1px 0px 8px -2px rgba(86, 153, 196, 0.75);
      }

      .smooth-scroll-text{
        font-weight: 500;
      }
    }

    &.active{
      .smooth-scroll-icon{
        opacity: 1;
        box-shadow: 2.5px -1.5px 3.96px -0.04px rgba(86, 153, 196, 0.4);

        svg{
          path{
            fill: $primary-blue !important;
          }
        }

        .icon{
          font-weight: 400;
        }
      }

      .smooth-scroll-text{
        left: 0;
        letter-spacing: 0.5px;
        font-weight: 600;
      }
    }
  }

  &:hover{
    .screen--smooth-nav{
      .smooth-scroll-text{
        opacity: 1;
        visibility: visible;
        padding-left: 46px;
        box-shadow: 4px 0px 4px -1px rgba(86, 153, 196, 0.2);
      }
    }
  }
}