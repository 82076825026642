// Carousel
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

@import "../../base/vars";
@import "../../base/components/key-numbers";
@import "../../base/components/screen-smooth-nav";
@import "../../base/components/support-section-big";
//@import "../../base/components/partners";
@import "../../base/components/more-bookings-carousel";
//@import "../../base/components/reviews-slider";
@import "../../base/components/features-list";
//@import "../../base/components/templates-slider";
@import "../../base/components/raiting-block";
@include break-min($mob){
  @import "../../base/components/our-app-section";
  @import "tutorial";
  //@import "covid-developments";
}
@import "../../base/components/directory-section";
@import "../../base/components/templates-list";
@import "../../base/components/covid-badge";

@import "main-screen";
@import "category";
@import "security-matters";
@import "trial";
@import "templates";
@import "reviews-carousel";
@import "features";
//@import "additional-info-sections";
//@import "blog-sections";
@import "offer-cardless";

.our-app,
#tutorial,
.covid-developments{
  @include break-max($mob){
    display: none;
  }
}

.our-app{
  padding: 70px 0 180px 0;

  @include break-max(1200px){
    padding: 70px 0 100px 0;
  }

  .features--shape{
    top: auto;
    bottom: -180px;

    @include break-max(1920px){
      bottom: -130px;
    }
    @include break-max(1440px){
      bottom: -72px;
    }
  }
}