.features__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    max-width: calc(33% - 100px);
    flex-basis: calc(33% - 100px);
    margin: 0 50px 40px;
    text-align: center;
    transition: .3s ease;
    @include break-min(1200px) {
      min-height: 265px;
      margin: 0 50px 15px;
    }

    @include break-max($tab) {
      max-width: calc(50% - 50px);
      flex-basis: calc(50% - 50px);
      margin: 0 25px 30px;
    }

    @include break-max($mob) {
      display: flex;
      max-width: 100%;
      flex-basis: 100%;
      margin: 0 0 30px;
      text-align: left;
    }
    picture {
      position: relative;
      display: block;
      max-width: 100px;
      max-height: 100px;
      flex-shrink: 0;
      margin: 0 auto 10px;
      @include break-max($mob) {
        max-width: 80px;
        min-width: 80px;
        max-height: 80px;
        margin-right: 15px;
      }
      &:before {
        position: absolute;
        content: '';
        background: $white;
        width: 170%;
        height: 170%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        box-shadow: 0 -15px 15px 0 rgba(86, 153, 196, 0.1);
        z-index: -1;
        opacity: 0;
        transition: .3s ease;
      }

      img{
        width: 100%;
      }
    }
    .features__content{
      @include break-max($mob){
        width: 100%;
      }
    }
    .title {

      @include break-max($mob) {
        margin-bottom: 5px;
      }
    }
    .bold {
      color: $dark-blue;
    }
    @include break-min(1200px) {
      p {
        max-height: 1.6em;
        overflow: hidden;
        transition: all .5s ease;
      }
      &:hover {
        transform: translateY(-15px);

        picture:before {
          opacity: 1;
        }

        p {
          max-height: 220px;
          transition: all .5s ease;
        }
      }
    }

    &.new-item{
      display: none;
    }
  }

  &.features__list-full-content-view{
    justify-content: space-around;
    align-items: flex-start;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 42%;
      flex-basis: 42%;
      margin: 0 0 40px 0;
      text-align: left;
      transition: .3s ease;
      @include break-min(1200px) {
        min-height: 140px;
        margin: 0 0 30px 0;
      }

      @include break-max($tab) {
        max-width: 45%;
        flex-basis: 45%;
        margin: 0 0 30px 0;
      }

      @include break-max($mob) {
        display: flex;
        max-width: 100%;
        flex-basis: 100%;
        margin: 0 0 30px;
        text-align: left;
      }
      picture {
        position: relative;
        display: block;
        max-width: 100px;
        max-height: 100px;
        flex-shrink: 0;
        margin: 0 15px 0 0;
        @include break-max($mob) {
          max-width: 80px;
          min-width: 80px;
          max-height: 80px;
          margin-right: 15px;
        }
        &:before {
          display: none;
        }

        img{
          width: 100%;
        }
      }
      .features__content{
        @include break-max($mob){
          width: 100%;
        }
      }
      .bold {
        color: $dark-blue;
      }
      @include break-min(1200px) {
        p {
          max-height: unset;
          overflow: visible;
          transition: all .5s ease;
        }
        &:hover {
          transform: translateY(0);

          picture:before {
            opacity: 1;
          }

          p {
            max-height: unset;
            transition: all .5s ease;
          }
        }
      }
    }
  }

  &.features__list-v2{
    justify-content: space-around;
    align-items: flex-start;

    li {
      max-width: calc(33.33% - 30px);
      flex-basis: calc(33.33% - 30px);
      margin: 0 15px 40px 15px;
      @include break-min(1200px) {
        min-height: 140px;
        margin: 0 15px 30px 15px;
      }

      @include break-max($tab) {
        max-width: calc(50% - 30px);
        flex-basis: calc(50% - 30px);
        margin: 0 15px 30px 15px;
      }

      @include break-max($mob) {
        display: flex;
        max-width: 100%;
        flex-basis: 100%;
        margin: 0 0 30px;
        text-align: left;
      }

      picture {
        &:before {
          display: none;
        }
      }

      @include break-min(1200px) {
        p {
          max-height: unset;
          overflow: visible;
          transition: all .5s ease;
        }
        &:hover {
          transform: translateY(0);

          picture:before {
            opacity: 1;
          }

          p {
            max-height: unset;
            transition: all .5s ease;
          }
        }
      }
    }
  }
}