#trial {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;

  @include break-min(1025px) {
    padding: 130px 0;
  }

  &.trial--second {
    padding: 50px 0 0 0;

    @include break-max($mob) {
      padding: 50px 0;
    }

    .trial {
      &__container {
        align-items: center;
      }

      &__picture {
        img {
          max-width: 100%;
          max-height: 400px;
          width: auto;
        }
      }

      &__content {
        margin-left: 70px;

        @include break-max($mob) {
          margin-left: 0;
        }
      }
    }
  }

  .trial {
    &__header {
      text-align: center;
      margin-bottom: 45px;

      @include break-max($mob) {
        margin-bottom: 25px;
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      @include break-max($mob) {
        flex-direction: column;
      }
    }

    &__picture {
      max-width: 50%;
      flex-basis: 50%;
      flex-shrink: 0;

      @include break-max($mob) {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 30px;
        text-align: center;
      }

      img {
        display: block;
        width: 100%;
        max-width: 400px;
        margin-left: auto;

        @include break-max($mob){
          margin-right: auto;
          max-height: 350px;
        }
      }
    }

    &__list {
      @include break-max($mob) {
        display: inline-block;
      }

      li {
        font-size: $main-text;
        color: $dark-blue;
        margin-bottom: 20px;

        @include break-max($mob) {
          margin-bottom: 15px;
          text-align: left;
        }

        &:last-child {
          margin-bottom: 0;
        }

        //i {
        //  width: 25px;
        //  height: 20px;
        //  font-size: 20px;
        //  color: $primary-blue;
        //  font-weight: $normal;
        //  text-align: center;
        //  margin-right: 20px;
        //}
        .far, .fas,
        .ico{
          width: 25px;
          height: 21px;
          margin-right: 20px;
          color: $dark-blue;
          font-size: 18px;
          font-weight: 300;

          path{
            fill: $dark-blue;
          }
        }
      }
    }

    .phantom-block {
      display: none;

      @include break-max($mob) {
        display: block;
        width: 100%;
        height: 1px;
      }
    }

    &__content {
      max-width: 50%;
      flex-basis: 50%;
      margin-left: 120px;

      @include break-max($tab) {
        margin-left: 70px;
      }

      @include break-max($mob) {
        max-width: 100%;
        flex-basis: 100%;
        width: 100%;
        margin: 0;
        text-align: center;
      }
    }

    &__btn {
      margin-top: 50px;
      @include break-max($mob) {
        margin-top: 20px;
      }
    }

    &__cursive {
      position: relative;
      margin: 40px 0 0 15px;

      @include break-max($tab) {
        margin: 30px 0 15px;
      }

      @include break-max($mob) {
        margin: 25px 0 0;
        display: inline-block;
        padding: 0 30px;
      }

      .arrow {
        background-image: url('../../../images/icons/arrow.svg');
        width: 75px;
        height: 75px;
        bottom: -35px;
        left: -60px;
        transform: rotate(150deg);

        @include break-max($mob) {
          left: -25px;
        }
      }
    }
  }

  &.dot-nav--section{
    .shape{
      position: absolute;
      bottom: -310px;
      left: 50%;
      transform: translateX(-50%);
      min-width: 175%;
      min-height: 400px;
      border-radius: 40% 60% 0 0;
      box-shadow: -20px -20px 20px -9px rgba(86, 153, 196, 0.11);
      pointer-events: none;
      z-index: -1;

      @include break-max($mob){
        min-width: 1200px;
        bottom: -346px;
      }
    }
  }
}