.support-section {
  min-height: auto;
  position: relative;
  overflow: hidden;

  .support {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__content {
      min-width: 360px;
      padding-right: 20px;

      .title {
        margin-bottom: 10px;

        @include break-max($mob){
          margin-bottom: 5px;
        }
      }

      .p {
        margin-bottom: 30px;
      }

      .btn-bar{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include break-max($mob){
          justify-content: center;
          align-items: center;
        }
      }

      .cursive {
        display: inline-block;
        position: relative;
        max-width: 380px;
        margin-top: 30px;
        padding-right: 30px;

        @include break-max($mob) {
          max-width: 86%;
          margin-top: 25px;
          line-height: 1.3;
          padding-left: 0;
          padding-right: 0;
        }

        .arrow {
          background-image: url('../../../images/icons/arrow.png');
          width: 80px;
          height: 40px;
          right: -48px;
          top: 0;
          transform: rotate(110deg);
          @include break-max($mob) {
            top: -20px;
            right: -70px;
            background-position: 30px 0px;
            transform: rotate(140deg);
          }
        }
      }

      &.support__content-enterprise{
        min-height: 560px;

        @include break-max(1920px){
          min-height: 420px;
        }
        @include break-max(1200px){
          min-height: auto;
        }

        .p{
          margin-bottom: 0;
        }

        .link{
          margin-bottom: 35px;
          padding: 0;
          font-weight: bold;
        }
      }
    }

    &__benefits {
      margin-bottom: 30px;
      @include break-max($tab) {
        display: none;
      }

      li {
        display: flex;
        color: $secondary-text-color;
        font-size: $secondary-text;
        margin-bottom: 10px;
        a{
          display: inline-block;
          margin: 0 5px;
        }

        svg{
          margin-right: 20px;
          height: 18px;
          path{
            fill: $primary-blue;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__picture {

      img {

      }
    }

    &__info {
      position: absolute;
      width: 120px;
      top: 130px;
      //right: -25px;
      right: 0;
      display: none;

      @include break-max($tab) {
        top: 70px;
        //right: -25px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 100px;
        top: 50px;
      }
      @include break-max($mob) {
        width: 120px;
        left: 0;
      }
    }

    &__name {
      display: block;
      font-family: $mulish;
      font-weight: $bold;
      color: $dark-blue;
      font-size: 18px;
      margin-bottom: 0;
    }

    &__position {
      display: block;
      font-family: $mulish;
      font-weight: $normal;
      font-size: 14px;
      color: $secondary-text-color;
      line-height: 1.3;
    }
  }

  .shape--bottom {
    display: block;
    position: absolute;
    bottom: -10px;
    width: 100%;
    min-width: 1920px;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    @include break-max(1440px){
      min-width: 1440px;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .counter-key-number--support{
    overflow: hidden;
    top: 0;

    .counter-key-number--body{
      top: -20px;

      @include break-max(1440px){
        top: -10px;
      }
    }
  }

  &--big{
    padding-top: 210px;
    padding-bottom: 20px;
    overflow: hidden;

    @include break-max(1200px){
      padding-top: 50px;
    }
    @include break-max(1200px){
      padding-bottom: 60px;
    }
    @include break-max($mob){
      padding-bottom: 68px;
    }


    .support {

      &__container {
        @include break-max($tab){
          justify-content: flex-end;
        }
        @include break-max($mob){
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }

      }

      &__content {
        min-width: 360px;
        width: 45%;
        padding: 25px 0 190px 20px;

        @include break-max(1920px){
          padding: 25px 0 160px 20px;
        }

        @include break-max(1200px){
          padding: 0 0 60px 20px;
          width: 58%;
        }

        @include break-max($tab){
          width: 45%;
          padding: 0;
        }

        @include break-max($mob){
          padding-bottom: 20px;
          width: 100%;
          min-width: 200px;
          max-width: 500px;
        }

        .cursive{
          @include break-max($mob){
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      &__picture {
        width: 100%;
        min-height: 500px;

        @include break-max(1200px){
          min-height: 470px;
        }

        @include break-max($tab){
          display: flex;
          align-items: flex-end;
          width: auto;
          max-width: 418px;
          margin-right: -20px;
          position: relative;
          min-height: 460px;
          overflow: hidden;
        }
        @include break-max($mob){
          margin-left: -20px;
          max-width: unset;
          min-height: auto;
        }

        img {
          position: absolute;
          height: 730px;
          width: auto;
          bottom: 96px;

          @include break-max(1920px){
            height: 600px;
          }

          @include break-max(1440px){
            bottom: 70px;
          }

          @include break-max(1200px){
            height: 467px;
          }
          @include break-max($tab){
            position: relative;
            min-width: 700px;
            height: 467px;
            bottom: 15px;
            margin-left: -20px;
          }
          @include break-max($mob){
            min-width: auto;
            height: auto;
            max-height: 467px;
            bottom: 0;
            margin: 0 auto;
          }
        }
      }

      &__info {
       display: none;
      }
    }
  }

  &--big-v2{
    padding: 200px 0 120px 0;

    @include break-max($tab + 1){
      padding-top: 150px;
    }

    @include break-max($mob){
      padding: 60px 0 50px 0;
    }

    .counter-key-number--support {
      top: 10px !important;
      left: 50px;

      .counter-key-number--back-number {
        font-size: 180px;
        line-height: 180px;

        .key-number {
          line-height: 180px;
        }
      }
    }

    .support {
      &__container {
        justify-content: center;

        @include break-max($mob){
          text-align: center;
          flex-direction: column;
          align-items: center;
        }
      }

      &__content {
        position: relative;
        min-width: 360px;
        padding: 40px 0 40px 20px;
        z-index: 3;

        @include break-max($mob){
          min-width: 200px;
          width: 100%;
          max-width: 500px;
          padding: 0 0 20px 0;
        }
      }

      &__picture {
        position: relative;
        min-height: 500px;
        width: 100%;
        max-width: 500px;

        @include break-max(1200px){
          margin-left: -10px;
        }
        @include break-max(900px){
          margin-right: -90px;
          margin-left: -65px;
        }
        @include break-max($mob){
          width: auto;
          margin: 0 -20px;
          text-align: center;
          min-height: auto;
        }

        img {
          position: absolute;
          width: auto;
          bottom: -40px;

          @include break-max($tab + 1){
            max-height: 620px;
          }

          @include break-max($mob){
            position: relative;
            height: auto;
            max-height: 440px;
            bottom: 0;
          }
        }
      }

      &__info {
        left: 91%;
        width: 140px;
        right: unset;
        display: inline-block;

        @include break-max(1200px){
          left: 10px;
          top: 0px;
          text-align: right;
        }

        @include break-max($mob){
          text-align: left;
          left: 10px;
          top: 15%;
        }
      }
    }
  }
}