.templates-list{
  margin: 0 -10px 30px -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include break-max($tab){
    justify-content: inherit;
    width: calc(100% + 20px);
    margin: 0 0 50px -10px;
    flex-flow: row nowrap;
    overflow: auto;
    scroll-snap-type: x mandatory;
  }

  &__item{
    position: relative;
    width: calc(33.33% - 20px);
    height: 354px;
    margin: 30px 10px 20px 10px;
    border-radius: 20px;
    text-align: center;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      background: linear-gradient(rgba(255,255,255,0) 0, $second-brand-color 80%, $second-brand-color 100%);
    }

    @include break-max(1200px){
      height: 100%;

      &:before{
        display: none;
      }
    }

    @include break-max($tab){
      width: calc(50% - 20px);
      max-width: 360px;
      scroll-snap-align: center;
      flex: none;
    }

    @include break-max($mob){
      min-width: 200px;
      width: 90%;
      margin: 25px 10px;
    }
  }

  &__item--card{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: $second-brand-color;
    border-radius: 20px;
    @include transition;

    @include break-max(1200px){
      position: relative;
      background: #fff;
    }
  }

  &__item-content{
    padding: 0 15px;
  }

  &__item-preview{
    display: block;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 20px;
    max-height: 248px;

    img{
      width: 100%;
    }
  }

  .title{
    margin-bottom: 5px;
  }

  .p{
    @include break-max(1200px){
      display: none;
    }
  }

  .btn-bar{
    padding-top: 15px;
    padding-bottom: 20px;

    .link--primary{
      margin-bottom: 20px;

      @include break-max($mob){
        margin-bottom: 0;
      }
    }

    .btn.btn--grey{
      @include break-max($mob){
        display: none;
      }
    }
  }

  .templates-list__item{
    &:hover{
      overflow: visible;

      &:before{
        display: none;
      }

      .templates-list__item--card{
        background: #fff;
        box-shadow: 0 0 15px 7px rgba(37, 50, 70, 0.1);
        z-index: 3;
      }
    }
  }

}