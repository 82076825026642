.our-app{
  position: relative;
  padding: 130px 0 110px 0;

  @include break-max($mob){
    padding: 100px 0 60px 0;
  }

  &__body{
    display: flex;
    align-items: center;
    justify-content: center;

    @include break-max($mob){
      flex-direction: column;
    }
  }

  &__item-picture{
    width: 400px;
    min-width: 400px;
    margin-right: 50px;
    text-align: center;

    @include break-max($tab){
      margin-right: 25px;
    }

    @include break-max($mob){
      min-width: 200px;
      width: 100%;
      padding: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }

    picture{
      position: relative;
      display: inline-block;
      max-width: 400px;

      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 98%;
        width: 108%;
        transform: translate(-64%, -48%);
        border-radius: 50%;
        background: $second-brand-color;
        z-index: -1;
      }
    }

    img{
      position: relative;
    }
  }

  &__item-content{
    width: 100%;
    max-width: 600px;

    @include break-max($mob){
      text-align: center;
    }

    .title{
      &.title--h2{
        margin-bottom: 15px;
      }
      &.title--h3{
        margin-bottom: 5px;
      }
    }

    p{
      font-size: 14px;
    }
  }

  &__item{
    &--client-app{
      margin-bottom: 18px;
    }

    &-btn-bar{
      padding-top: 18px;
    }
  }

  .app-links{
    padding-top: 5px;
    justify-content: flex-start;

    @include break-max($mob){
      justify-content: center;
    }

    &__link{
      max-width: 120px;
      line-height: 1;
    }
  }

  .features--shape{
    position: absolute;
    top: -40px;
    width: 100%;
    min-width: 2500px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    filter: drop-shadow(0 -30px 16px rgba(86, 153, 196, 0.07));
    opacity: 0.98;

    @include break-max($fullHD){
      min-width: 1940px;
      top: -30px;
    }

    @include break-max(1440px){
      min-width: 1460px;
      top: -20px;
    }
    @include break-max($tab){
      min-width: 1040px;
      top: 0;
    }
    @include break-max($mob){
      min-width: 780px;
      top: 30px;
    }
    @include break-max($mob480){
      min-width: 480px;
    }

    &.shape--type-6{
      fill: #fff;
    }
  }
}