.dot-nav--section{
  @include break-max($tab + 1){
    min-height: auto;
  }
}

.main-section{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 160px;
  overflow: hidden;

  @include break-max($mob){
    min-height: 460px;
    padding-bottom: 190px;
  }

  &.main-top-section{
    @include break-max($mob){
      //padding-top: 210px;
    }
    @include break-max(480px){
      padding-bottom: 80px;
    }
    @media only screen and (min-width: $mob) and (max-width: $tab){
      padding-top: 175px;
    }

    //.covid-badge{
    //  @include break-min($mob){
    //    display: none;
    //  }
    //}

  }

  .main-section--pd{
    padding: 30px 0 30px 30px;

    @include break-max($tab){
      padding: 20px;
    }
    @include break-max($mob){
      padding: 20px 10px 0 10px;
    }

    .title {
      margin-bottom: 15px;
      display: inline-flex;
      flex-wrap: wrap;

      @include break-max($tab){
        justify-content: center;
        text-align: center;
      }

      .highlight{
        padding: 0 6px;
      }

      .divider{
        display: inline-block;
        width: 100%;

        @include break-max($mob){
          display: none;
        }
      }
    }

    .p {
      max-width: 500px;
      margin-bottom: 40px;

      @include break-max($tab){
        margin-left: auto;
        margin-right: auto;
      }
    }

    .btn-bar{
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 25px;

    }

    .app-links{
      justify-content: flex-start;

      @include break-max($tab){
        justify-content: center;
        text-align: center;
        margin-bottom: 15px;
      }
    }
  }

  .header_devices{
    position: relative;
    width: 100%;
    min-height: 500px;

    @include break-max($tab){
      min-height: 300px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    @include break-max($mob){
      display: none;
    }

    .device{
      picture{
        display: inline-block;
        border-radius: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        min-width: 600px;
        background-size: cover;

        @include break-max($tab){
          position: relative;
          min-width: 300px;
        }
      }
    }

    .animation {
      position: absolute;
      bottom: 30px;
      left: 30px;
      background: #fff;
      border-radius: 15px;
      width: 190px;
      min-width: 190px;
      border: 2px solid #e9ecf0;
      overflow: hidden;
      padding: 40px 0 30px;
      text-align: center;

      .social-links {
        display: inline-block;
        padding: 0 15px;
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .shadow-box{
    height: calc(100% - 4%);
    position: absolute;
    z-index: -1;
    width: 200%;
    top: 0;
    left: auto;
    right: -10%;
    border-radius: 0 0 90% 50%;
    box-shadow: 18px 25px 46px 0px rgba(86, 153, 196, 0.08);

    @include break-max($mob){
      height: calc(100% - 25%);
    }

    @include break-max($mob480){
      height: calc(100% - 28%);
    }
  }

  .items-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include break-max($tab){
      flex-direction: column;
      justify-content: center;
    }

    .items-row {
      width: 100%;
      max-width: calc(62% - 10px);

      //@include break-max($lap){
      //  max-width: calc(70% - 10px);
      //}

      @include break-max($tab){
        max-width: 100%;
        text-align: center;
      }

      .content-part{
        position: relative;
        @include break-max($mob){
          .title{
            margin-bottom: 25px;
            display: block;

            .divider{
              display: none;
            }

          }
          .p--secondary{
            display: none;
          }

          .btn-bar{
            .btn--secondary{
              display: none;
            }
          }
        }

      }

      .main-section--mob-main_mockup{
        display: none;
        width: 100%;
        max-width: 440px;

        @include break-max($mob){
          display: inline-block;
        }
      }

      &.items-row--image{
        max-width: calc(38% - 10px);
        //@include break-max($lap){
        //  max-width: calc(30% - 10px);
        //}

        @include break-max($tab){
          position: relative;
          max-width: 100%;
        }

        .show-on-mob{
          margin-top: 20px;
          text-align: center;
          width: 100%;

          .p{
            max-width: 480px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
          }

          @include break-min($mob){
            display: none;
          }
        }

        @include break-max($mob){
          .items-row--item {
            position: relative;
          }
        }
      }
    }

    .promo-video__btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 90px;
      top: 40%;
      left: 55%;
      transform: translate(-50%, -50%);
      border-radius: 50px;
      background: rgba(37, 50, 70, 0.3);
      box-shadow: 2px 4px 15px 3px rgba(45, 53, 71, 0.1);
      cursor: pointer;
      text-decoration: none;
      @include transition;
      z-index: 4;

      &:hover {
        background: rgba(37, 50, 70, 0.5);
      }

      &-icon {
        margin-left: 3px;
        font-size: 24px;
        height: 24px;
        color: $white;

        path{
          fill: #fff;
        }
      }

      &--mobile {
        display: none;
        top: 40%;
        width: 60px;
        height: 60px;

        .promo-video__btn-icon {
          font-size: 18px;
        }

        @include break-max($mob){
          display: flex;
          top: 45%;
          left: 50%;
        }
      }

      &--desktop {
        display: flex;

        @include break-max($mob){
          display: none;
        }
      }
    }
  }

  .cursive {
    //display: inline-flex;
    text-align: left;
    padding-top: 25px;
    margin-bottom: 10px;

    .arrow {
      left: -42px;
      top: -30px;
      transform: scale(-1, -1) rotate(10deg);
      width: 40px;
      height: 70px;
      background: url("../../../images/icons/arrow-black.svg") no-repeat;
    }
  }

  .content-part--by-ip{
    .p{
      max-width: unset;
      padding: 0;
      line-height: 28px;
      margin: 0 0 22px 0;
      @include break-min($tab){
        margin: 0;
      }
      span.p--secondary{
        display: inline !important;
      }

      .icon{
        position: relative;
        bottom: -0.46em;
        display: inline-block;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: contain !important;
        height: 28px;
        aspect-ratio: 2.3/1;
        &-moneris{
          bottom: -0.20em;
          background: url("../../../images/payment-methods/moneris.svg");
          aspect-ratio: 3/1;
          height: 37px;
        }
        &-stripe{
          background: url("../../../images/payment-methods/stripe.svg");
          background-size: 76px !important;
        }
        &-jcc{
          background: url("../../../images/payment-methods/jss.svg");
          background-size: 42px !important;
          aspect-ratio: 1.8/1;
        }
        &-revolut{
          background: url("../../../images/payment-methods/revolut.svg");
          background-size: 100px !important;
        }
        &-ecpay{
          background: url("../../../images/payment-methods/ecpay.svg");
          background-size: 120px !important;
          aspect-ratio: 2.7/1;
        }
        &-newebpay{
          background: url("../../../images/payment-methods/newebpay.svg");
          aspect-ratio: 6/1;
          background-size: 190px !important;
        }
        &-paypal{
          background: url("../../../images/payment-methods/paypal.svg");
          background-size: 110px !important;
          aspect-ratio: 3.1/1;
        }
      }
    }
  }
}