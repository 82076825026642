#security {
  position: relative;
  min-height: auto;

  @include break-min(1025px) {
    padding: 85px 0 150px 0;
  }
  @include break-max($tab) {
    padding-top: 40px;
  }
  @include break-max($mob) {
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .security {
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      @include break-max($tab){
        flex-direction: column;
      }

      //@include break-min($tab) {
      //  &.is-mobile {
      //    display: none;
      //  }
      //}
      //@include break-max($tab) {
      //  &.is-desktop {
      //    display: none;
      //  }
      //}

      .show-on-mob{
        text-align: center;
        margin-bottom: 15px;
        display: none;

        .security__title--mobile {
          @include break-max($mob) {
            margin-bottom: 10px;
          }
        }

        @include break-max($tab){
          display: block;
        }
      }
    }
    &__media {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 35%;
      flex-basis: 35%;

      @include break-max($tab) {
        max-width: 380px;
        margin: 20px auto;
      }

      img{
        max-width: 400px;
      }
    }
    &__content {
      max-width: calc(50% - 60px);
      margin-left: 60px;
      @include break-max($tab) {
        max-width: 100%;
        margin: 0;
        text-align: center;
      }
    }

    &__title {
      @include break-max($tab) {
        display: none;
      }
    }

    &__subtitle {
      margin-bottom: 15px;

      @include break-max($tab){
        display: none;
      }
    }

    &__text {
        margin-bottom: 20px;

        @include break-max($tab){
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }

        @include break-max($mob) {
          margin-bottom: 10px;
        }
    }
    &__logos {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 70px -30px 0;
      @include break-max($tab) {
        margin-top: 30px;
        img {
          min-width: 100px;
        }
      }
      @include break-max($mob){
        margin-left: -20px;
        margin-right: -20px;
      }
      li {
        margin: 0 15px;

        @include break-max($mob){
          margin-bottom: 20px;
        }

        img {
          min-width: 110px;
        }
      }
    }
  }

  .counter-key-number--security{
    bottom: -40px;
  }
}