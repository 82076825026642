#tutorial {
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  background: #f1f6ff;
  min-height: auto;
  height: auto;
  @include break-min(1024px) {
    padding: 120px 0
  }

  &.with-curved-line {
    padding-top: 0;
    .shape-tutorial-section{
      background: #fff;
      @include break-max($tab){
        margin-bottom: 40px;
      }

      path{
        fill: $second-brand-color;
      }
    }
  }

  .tutorial {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      @include break-max($mob){
        margin-bottom: 70px;
      }
    }

    &__content {
      max-width: 40%;
      margin-bottom: 70px;
      text-align: left;
      @include break-max($tab) {
        width: 100%;
        max-width: 640px;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    &__title {
      margin-bottom: 15px;
    }

    &__picture {
      position: relative;
      max-width: 50%;
      width: 100%;
      @include break-max($tab) {
        max-width: 100%;
      }

      img {
        display: block;
        margin: 0 auto;
        max-height: 450px;
        width: auto;
      }
    }

    &__btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100px;
        min-width: 200px;
        white-space: nowrap;
        @include break-max($mob) {
          bottom: 0px;
        }
      }

    &__cursive {
      position: absolute;
      left: -50px;
      bottom: 100px;
      @media (min-width: 768px) and (max-width: 1023px) {
        left: 15%;
      }
      @include break-max($mob) {
        left: 50%;
        transform: translateX(-50%);
        bottom: -45px;
      }

      .arrow {
        background-image: url('../../../images/icons/arrow.png');
        width: 70px;
        height: 35px;
        bottom: -40px;
        transform: rotate(200deg);
        @media (min-width: 768px) and (max-width: 1023px) {
          bottom: -50px;
          right: 10px;
          transform: rotate(220deg);
        }
        @include break-max($mob) {
          width: 60px;
          height: 60px;
          bottom: 10px;
          right: -70px;
          transform: rotate(140deg);
        }
      }
    }
  }

  .partners-block{
    margin-top: 50px;
    width: 100%;

    .section{
      height: auto;
      padding: 0;
    }
  }
}