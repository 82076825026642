#features {
  position: relative;
  text-align: center;
  padding: 60px 0 70px 0;

  @include break-min(1200px){
    padding: 120px 0;
  }
  @include break-max($mob){
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .features {
    &__header {
      text-align: center;
      margin-bottom: 80px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .phantom-item,
      .title,
      .features-tab-switch{
        width: 33.33%;

        @include break-max($tab){
          width: 100%;
        }
      }

      .features-tab-switch{
        @include break-max($tab){
          max-width: 650px;
          margin-top: 15px;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;
        }
      }

      .phantom-item{
        @include break-max($tab){
          display: none;
        }
      }

      @include break-max(1200px) {
        margin-bottom: 40px;
      }
      @include break-max($mob) {
        margin-bottom: 35px;
      }
    }
    &__footer{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px 10px;
    }
  }

  .features-tab-switch{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .features-tab-switch-item{
      @include break-max($tab){
        width: 33.33%;
      }
      a{
        display: inline-block;
        padding: 0 12px;
        color: #7a818f;
        line-height: 1.3;
        text-decoration: none;

        &:hover{
          color: darken(#7a818f, 6%);
        }
      }
      &:last-child{
        a{
          padding-right: 0;
        }
      }

      &.active{
        a{
          text-decoration: underline;
        }
      }

      @include break-max($tab){
        &:first-child{
          a{
            padding-left: 0;
          }
        }
      }
    }
  }

  .btn {
    //margin-top: 20px;
    //margin-bottom: 20px;
    //
    //@include break-max($tab768){
    //  margin-bottom: 0;
    //}
  }

  .counter-key-number{
    bottom: -50px;
    z-index: -1;

    @include break-max(1440px){
      bottom: -42px;
    }
  }

  .shape--top{
    width: 100%;
    position: absolute;
    margin-bottom: 0;
    bottom: -130px;
    overflow: hidden;
    height: 180px;
    z-index: 1;

    @include break-max($mob){
      display: none;
      top: 100%;
      height: 120px;
    }

    .shadow-box{
      position: absolute;
      top: 50px;
      height: 115vh;
      width: 250%;
      background: #fff;
      border-radius: 30% 70% 0 0;
      left: -43%;
      box-shadow: 0px 31px 55px 0px rgba(86, 153, 196, 0.8);

      @include break-max($mob){
        top: 54px;
        left: -38%;
      }
    }
  }
}