// Covid-19 vaccination badge
.covid-badge,
.float-badge{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 52px;
  position: absolute;
  top: 70px;
  left: 0;
  border-radius: 0 10px 10px 0;
  box-shadow: 2px 2px 15px rgba(45, 51, 72, 0.1);
  background: $white;

  &:hover {
    text-decoration: none;
  }

  &__component{
    @include break-max($mob){
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      margin-bottom: 25px;
      padding: 0 15px;

      .float-badge{
        position: relative;
        flex-direction: row-reverse;
        flex: 1;
        width: 100%;
        max-width: 170px;
        min-width: 150px;
        padding: 0 15px;
        margin: 0;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        border-radius: 10px;

        &__text{
          padding-left: 0;
          padding-right: 0;
          margin: 0;
        }

        &__image{
          margin-left: 0;
          margin-right: 12px;
        }

        &:last-child{
          margin-right: 0;
        }
        &.ua-badge{
          //@include break-max(640px){
          //  flex: unset;
          //  width: 100%;
          //  max-width: 348px;
          //
          //  .float-badge__text{
          //    max-width: unset;
          //    b{
          //      display: inline;
          //      width: auto;
          //    }
          //  }
          //}
        }
      }
    }
  }

  &__text {
    margin-right: 5px;
    padding: 10px 0 10px 45px;
    max-width: 140px;
    font-family: 'Mulish' , sans-serif;
    font-size: 12px;
    line-height: 1.4;
    color: $dark-blue;
    text-align: center;
    @include break-min($mob){
      width: 140px;
    }
    b{
      font-weight: bold;
    }
  }

  &__image {
    margin-bottom: -10px;
    margin-right: 5px;
  }

  @include break-max($mob){
    top: 140px;
  }

  &.iso-badge{
    @include break-min($mob){
      flex-direction: row-reverse;
    }

    .float-badge{
      &__image{
        margin-bottom: 0;
        width: 38px;
        @include break-min($mob){
          margin: 0 5px 0 30px;
        }
      }

      &__text {
        @include break-min($mob){
          padding-left: 12px;
          padding-right: 24px;
          margin-right: 0;
        }

        b{
          display: block;
          width: 100%;
        }
      }

    }
  }

  &.gdpr-badge,
  &.ua-badge{
    @include break-min($mob){
      top: 134px;
      flex-direction: row-reverse;
    }

    .float-badge{
      &__image{
        margin-bottom: 0;
        width: 36px;
        @include break-min($mob){
          margin: 0 5px 0 30px;
        }
      }

      &__text {
        @include break-min($mob){
          padding-left: 12px;
          padding-right: 24px;
          margin-right: 0;
        }

        b{
          display: block;
          width: 100%;
        }
      }

    }
  }

  &.gdpr-badge{
    @include break-min($mob){
      top: 134px;
      flex-direction: row-reverse;
    }

    .float-badge{
      &__text {
        @include break-min($mob){
          padding-left: 12px;
          padding-right: 24px;
          margin-right: 0;
        }

        b{
          display: block;
          width: 100%;
          font-size: 13px;
          letter-spacing: 2px;
          line-height: 1;
        }
      }

    }
  }
}