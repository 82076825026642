.section--reviews-carousel{
  position: relative;
  padding: 65px 0;
  text-align: center;

  .container{
    position: relative;
    z-index: 3;
  }

  .reviews--header{
    margin-bottom: 60px;

    .title--h2{
      text-align: center;
      margin-bottom: 0;
    }

    @include break-max($tab){
      margin-bottom: 40px;
    }
  }

  .reviews--slider-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 40px;

    @include break-max($mob){
      max-width: 560px;
    }

    .reviews--slider-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 49%;
      background: #fff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px rgba(86, 153, 196, 0.15);
      padding: 0 30px 20px 30px;

      @include break-max($tab){
        padding: 0 20px 20px;
      }

      @include break-max($mob){
        width: 100%;
        margin-bottom: 80px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    .review--item-header{
      margin-bottom: 10px;
      text-align: center;
      color: $dark-blue;
    }

    .reviews--slider-picture{
      display: inline-block;
      margin-top: -40px;
      margin-bottom: 20px;

      img{
        max-height: 150px;
      }
    }

    .review--company-owner{
      font-family: $mulish;
      font-weight: bold;
      color: $dark-blue;
    }

    .review--item-body{
      text-align: left;
      margin-bottom: 20px;
    }

    .review--item-footer{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: auto 0 0 0;

      .review--footer-item{
        min-width: 50%;
        text-align: left;

        img{
          height: auto;
          width: auto;
          max-height: 70px;
          max-width: 100%;
        }

        &.footer-item-link{
          text-align: right;
          margin: 0 0 0 auto;

          .link{
            padding: 0;
          }
        }
      }
    }
  }

  .center{
    margin: 50px 0;
    display: block;
    width: 100%;
    text-align: center;

    @include break-max($mob){
      margin: 15px 0;
    }
  }

  .counter-key-number{
    z-index: -1;
    bottom: -40px;
  }


  .shape--top{
    width: 100%;
    position: absolute;
    margin-bottom: 0;
    top: -50px;
    overflow: hidden;
    height: 180px;
    z-index: 1;

    @include break-max($mob){
      display: none;
      top: 0;
      height: 120px;
    }

    .shadow-box{
      position: absolute;
      top: 50px;
      height: 115vh;
      width: 250%;
      background: #fff;
      border-radius: 30% 70% 0 0;
      left: -43%;
      box-shadow: 0px 31px 55px 0px rgba(86, 153, 196, 0.8);

      @include break-max($mob){
        top: 54px;
        left: -38%;
      }
    }
  }
}