#templates {
  position: relative;
  background: #f1f6ff;
  text-align: center;

  @include break-min(1025px) {
    padding: 100px 0 150px;
  }

  @include break-max($tab) {
    padding-bottom: 100px;
  }
  @include break-max($mob) {
    padding-bottom: 80px;
  }

  .templates--header {
    text-align: center;
    margin-bottom: 0;
  }

  .shape--bottom {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: -10px;
  }

  .counter-key-number--templates{
    top: -20px;
  }
}