.more-bookings-slider{
  padding-top: 70px;
  padding-bottom: 60px;

  @include break-max($mob) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .bookings-slider--row{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include break-max($tab){
      flex-direction: column;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    .book-sl--item{

      &.book-sl--item-main{
        width: calc(100% - 500px);
        padding-right: 30px;
        text-align: center;

        @include break-max($tab){
          max-width: 700px;
          margin: 0 auto;
          width: 100%;
          padding-right: 0;
        }

        .book-sl--main{
          margin-bottom: 0;
        }

        .decor{
          display: inline-flex;
          position: relative;
          text-align: center;
          padding-top: 0;
          font-size: 24px;

          @include break-max($tab) {
            display: none;
          }

          .arrow{
            &:before{
              content: '';
              display: block;
              position: absolute;
              top: -10px;
              left: -50px;
              background: url("../../../images/icons/arrow.svg") no-repeat;
              background-size: contain;
              height: 70px;
              width: 70px;
              transform: scale(-1, -1) rotate(-20deg);
            }
          }
        }
      }
      &.book-sl--item-nav{
        width: 500px;

        @include break-max($tab){
          width: 100%;
        }
        @include break-max($mob){
          width: calc(100% + 30px);
          margin: 0 -15px;
        }

        .book-sl--nav{
          margin-bottom: 0;
          width: 100%;

          > div{
            @include break-min(768px){
              padding: 0 !important;
            }
            width: 100%;

            > div{
              @include break-min(768px){
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100% !important;
                height: 100%;
                transform: none !important;
              }

              > div {
                @include break-min(768px){
                  width: calc(33.33% - 5px) !important;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                min-height: 154px;
                padding: 25px 10px 15px 10px;
                margin-bottom: 0;
                border-radius: 10px;
                opacity: 1;
                cursor: pointer;
                transition: .3s ease;
                outline: none;
                text-decoration: none;
                -webkit-tap-highlight-color: transparent;
                @include break-max($mob){
                  min-width: 250px;
                  max-width: 250px;
                  transform: scale(0.94);
                  background: rgba($second-brand-color, 0.36);
                  transition: all 0.4s ease-in;
                  padding: 25px 15px 15px 15px;
                }

                .book-sl--nav-icon{
                  height: 34px;
                  min-width: 60px;
                  margin-bottom: 12px;
                  text-align: center;

                  img, svg{
                    height: 34px;
                    display: inline-block;
                  }

                  svg{
                    path{
                      fill: #ABBCCC;
                    }
                  }
                }

                .book-sl--nav-txt{
                  text-align: center;
                  padding: 0 5px;
                }

                .book-sl--nav-name{
                  font-size: 16px;
                  display: block;
                  width: 100%;
                  line-height: 1.3;
                  text-align: center;
                  color: $secondary-text-color;
                  min-height: 36px;
                  visibility: visible;
                  opacity: unset;
                }

                a.book-sl--nav-name{
                  &:hover{
                    color: $primary-blue;
                  }
                }

                a{
                  display: block;
                  margin: 5px 0 0;
                  padding: 4px 0;
                  font-size: 14px;
                  line-height: 18px;
                  visibility: hidden;
                  opacity: 0;
                  @include break-max($mob) {
                    font-size: 16px;
                  }
                }

                &.slick-current{
                  background: $second-brand-color;
                  opacity: 1;
                  @include break-max($mob){
                    transform: scale(1);
                  }

                  .book-sl--nav-icon{
                    svg{
                      path{
                        fill: $dark-blue;
                      }
                    }
                  }

                  .book-sl--nav-name{
                    color: $dark-blue;
                  }

                  a{
                    opacity: 1;
                    visibility: visible;
                  }
                }
                &:hover{
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

  }

  &__btn-bar{
    text-align: center;
    padding-top: 32px;
    @include break-min($tab){
      padding-top: 12px;
    }
  }
}