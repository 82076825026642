.directory-section{
  margin-bottom: 50px;
}
.categories-section{
    min-height: 80vh;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .category--header{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .phantom-item, .title{
        width: 50%;

        @include break-max($tab){
          width: 100%;
        }
      }
      .phantom-item{
        width: 25%;
        text-align: right;

        .link{
          padding-right: 0;
          padding-left: 20px;
        }

        @include break-max($tab){
          display: none;
        }
      }
    }

    .title--h2{
      margin: 40px 0;
      text-align: center;

      @include break-max($mob){
        margin: 0 0 20px 0;
      }

      @include break-max($lap){
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;

        .highlight{
          display: inline-block;
          width: 100%;
        }
      }
    }

    .categories--row{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;

      .categories-row--item{
        display: flex;
        width: 33.33%;
        max-width: 33.33%;

        @include break-max($tab){
          width: 50%;
          max-width: 50%;

          &:nth-child(2){
            order: -3;
          }

          &:nth-child(3){
            order: inherit;
          }

          &:nth-child(5){
            order: 1;
          }
        }

        @include break-max($mob){
          width: 100%;
          max-width: 100%;

          &:nth-child(2){
            order: inherit;
          }

          &:nth-child(3){
            order: inherit;
          }

          &:nth-child(5){
            order: inherit;
          }
        }

        .category{
          width: 100%;
          min-height: 340px;
          position: relative;
          overflow: hidden;
          background-color: $category-def;
          transition: background-color 0.5s ease;

          @include break-max(1920px){
            min-height: 310px;
          }

          @include break-max($tab){
            min-height: 220px;
            overflow: visible;
            padding: 0 20px;
            display: flex;
          }

          @include break-max($mob){
            min-height: inherit;
          }

          .category-side--front{
            display: flex;
            align-items: flex-end;
            overflow: hidden;
            height: 100%;
            padding: 20px 200px 20px 0;

            @include break-max(1920px){
              padding-right: 120px;
            }

            @include break-max($tab){
              min-height: 120px;
              padding: 0;
              overflow: visible;
              width: 50%;
            }

            @include break-max($mob){
              align-items: center;
              justify-content: flex-start;
            }

            img{
              position: relative;
              left: 0;
              min-height: 300px;
              transition: all 0.5s ease-out;
              display: block;

              &.hide-on-mob{
                @include break-max($mob){
                  display: none;
                }
              }

              &.show-on-mob{
                display: none;

                @include break-max($mob){
                  display: block;
                }
              }

              @include break-max(1920px){
                min-height: inherit;
              }

              @include break-max($mob){
                min-height: 60px;
                max-height: 100px;
                width: auto;
              }
            }
          }

          .category-side--back{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 20px 30px;

            @include break-max($tab){
              position: static;
              justify-content: flex-start;
              align-items: flex-end;
              padding: 20px 0 0 0;
              width: 50%;
              height: auto;
            }

            @include break-max($mob){
              padding-top: 0;
              justify-content: center;
              align-items: flex-end;
            }

            .title--h3{
              text-align: center;
              padding-left: 70%;
              width: 100%;
              margin-bottom: 30px;
              transition: all 0.5s ease-out;

              @include break-max($lap){
                padding-left: 55%;
              }

              @include break-max($tab){
                text-align: right;
                padding-left: 0;
                margin-bottom: 10px;
              }

              @include break-max($mob){
                font-size: 18px;
                margin-bottom: 5px;
                text-align: right;
              }
            }

            .simulate-link{
              display: none;

              @include break-max($tab){
                display: inline-flex;
                align-items: center;
                line-height: 1.2;

                svg,
                i{
                  margin-left: 10px;
                  height: 16px;
                  path{

                  }
                }
              }
            }

            .p{
              max-width: 400px;
              position: relative;
              opacity: 0;
              right: -50%;
              margin-bottom: 40px;
              color: rgba(255, 255, 255, 0.3);
              transition: all 0.5s ease-out;

              @include break-max($tab){
                display: none;
              }
            }

            .link{
              background: #fff;
              height: 40px;
              min-height: 45px;
              opacity: 0;
              text-decoration: none;

              i{
                margin-left: 10px;
              }

              @include break-max($tab){
                opacity: 1;
                background: transparent;
                padding-right: 0;
                padding-left: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;

                &:after, i, .txt{
                  display: none;
                }
              }

              //@include break-max($mob){
              //  display: none;
              //}
            }
          }

          &:hover{
            background-color: $category-def-hover;

            .category-side--front{
              img{
                left: -40%;
                animation: fadeOutCategoryImage 0.5s ease-out forwards;

                @include break-max($tab){
                  animation: none;
                  left: 0;
                }
              }
            }

            .category-side--back{

              .title--h3{
                padding-left: 0;
                color: #fff;
              }

              p{
                color: #fff;
                right: 0;
                opacity: 1;
                transition: all 0.5s ease-out;

                @include break-max($mob){
                  display: none;
                }
              }

              .link{
                animation-delay: 1.5s;
                animation: fadeIn 1.2s ease-out forwards;
                opacity: 1;

                @include break-max($tab){
                  animation: none;
                  display: inline-flex;
                  background: transparent;
                }

                @include break-max($mob){
                  animation: none;
                  //display: none;
                  font-weight: 500;
                  background: transparent;
                  color: #fff;
                }
              }
            }
          }

          &.category--beauty{
            background-color: $category-beauty;

            &:hover{
              background-color: $category-beauty-hover;
            }

            @include break-max($mob){
              flex-direction: row-reverse;

              .category-side--front{
                justify-content: flex-end;
              }

              .category-side--back{
                @include break-max($mob){
                  align-items: flex-start;
                }

                .title--h3{
                  text-align: left;
                }
              }
            }
          }

          &.category--events{
            background-color: $category-events;

            &:hover{
              background-color: $category-events-hover;

              .category-side--back{
                .title--h3,
                .p{
                  color: $dark-blue;
                }
              }
            }

            @include break-max($mob){
              flex-direction: row-reverse;

              .category-side--front{
                justify-content: flex-end;
              }

              .category-side--back{
                align-items: flex-start;

                .title--h3{
                  text-align: left;
                }
              }
            }

            .category-side--front{
              align-items: flex-end;

              img{
                bottom: -30px;

                @include break-max($tab){
                  bottom: 0;
                }
              }
            }
          }

          &.category--medical{
            background-color: $category-medical;

            &:hover{
              background-color: $category-medical-hover;
            }

            @include break-max($mob){
              flex-direction: row-reverse;

              .category-side--front{
                justify-content: flex-end;
              }

              .category-side--back{
                @include break-max($mob){
                  align-items: flex-start;
                }

                .title--h3{
                  text-align: left;
                }
              }
            }

            .category-side--front{
              align-items: flex-start;

              img{
                top: -30px;

                @include break-max($tab){
                  top: 0;
                }
              }
            }
          }

        }
      }
    }

    .btn-bar{
      padding: 20px 20px 0 20px;
      display: block;
      text-align: center;
      @include break-min($tab){
        display: none;
      }
    }
  }


@keyframes fadeOutCategoryImage {
  0%{
    opacity: 0.9;
  }
  100%{
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeInCategoryData {
  0%{
    opacity: 0.1;
  }
  100%{
    opacity: 1;
  }
}