.directory-section{
  background: $second-brand-color;
  padding-top: 70px;
  padding-bottom: 25px;

  @include break-max($tab){
    padding: 50px 0 25px 0;
  }

  .decor {
    line-height: 1;
    display: block;
    margin-bottom: 40px;

    @include break-max($tab){
      margin-bottom: 20px;
    }

    @media (max-width: $mob){
      font-size: 24px;
    }
  }

  .directory-section-block{

    .logo-directory{
      display: block;
      text-align: center;
      height: 110px;
      width: 230px;
      //background: url("../../../images/main-page/directory/directory-logo.svg") no-repeat center;
      position: relative;
      margin: 0 auto;

      > img{
        max-width: 100%;
        height: auto;
        max-height: 110px;
      }

      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -70px;
        height: 70px;
        width: 70px;
        background: url("../../../images/main-page/directory/arrow-cursive.svg") no-repeat center;
        transform: scale(-1 , 1) rotate(-22deg);
      }
    }

    .directory-section--header{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .title{
        margin-bottom: 10px;

        @include break-max($tab){
          font-size: 26px;
        }
      }

      .sub-title{
        margin-bottom: 15px;
      }
    }

    .directory-part{
      max-width: 1024px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;

      @include break-max($mob){
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
      }

      .directory-part--first{
        text-align: left;
        padding: 20px 20px 0 0;
        width: 100%;

        @include break-max($mob){
          text-align: center;
        }

        .txt{
          margin-bottom: 5px;
          max-width: 550px;

          @include break-max($tab){
            max-width: 100%;
          }
        }

        .btn-bar{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin: 0 -5px;

          @include break-max($tab){
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
          }

          @include break-max($mob){
            justify-content: center;
          }

          .btn{
            margin: 5px;
          }
        }

        @include break-max($tab){
          padding-left: 20px;
        }
        @include break-max($mob){
          margin-bottom: 20px;
        }
      }
      .directory-part--second{
        width: 350px;
        min-width: 350px;

        img{
          margin-bottom: -60px;
        }

        @include break-max($tab){
          width: 300px;
          min-width: 300px;

          img{
            margin-bottom: 0;
          }
        }

        @include break-max($mob){
          width: 250px;
          min-width: 250px;

          img{
            margin-bottom: -45px;
          }
        }
      }
    }

  }
}