.rating-block{
  max-width: 540px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: -20px;
  margin-right: -20px;

  @include break-max($mob){
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
  }

  &:not(.clickable) {
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 58px;
      margin: 10px 15px;

      @include break-max($mob){
        width: calc(50% - 15px);
        margin: 10px 0;
        text-align: center;
      }
    }
  }

  &.clickable {
    li {
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 58px;
        margin: 10px 15px;

        @include break-max($mob){
          width: calc(50% - 15px);
          margin: 10px 0;
          text-align: center;
        }
      }

      &:hover, &:focus {
        * {
          text-decoration: none;
        }
      }
    }
  }

  li {
    &:hover{
      .rating-block--stars{

        .ico{
          color: $category-events-hover;

          path{
            fill: $category-events-hover;
          }
        }

        .rate{
          color: $dark-blue;
        }
      }
    }

    img{
      max-width: 120px;
      height: 40px;
      display: inline-block;
    }

    .rating__logo{
      max-width: 120px;
      height: 34px;
      margin-bottom: 10px;
      @include transition;

      &--finances {
        height: 39px;
      }
    }

    .rating-block--stars{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-top: -10px;

      .ico{
        height: 11px;
        color: #d7dee5;

        path{
          @include transition;
        }
      }

      .rate{
        margin-left: 10px;
        color: #d7dee5;
        @include transition;
      }
    }
  }

  &.rating-block__has-badge{
    max-width: 684px;

    li{
      min-width: 108px;
    }

    @include break-max($mob){
      position: relative;
      max-width: 396px;
      padding-top: 74px;

      li{
        max-width: 133px;
      }
    }
    .rating-block__item-badge{
      opacity: 1 !important;
      @include break-max($mob){
        position: absolute;
        max-width: 103px;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      a{
        display: block;
        text-align: center;
      }
      img{
        min-width: 103px;
        max-width: 103px;
        height: auto !important;
      }
    }
  }
}